<template>
  <AppView>
    <!-- content @s -->
    <div class="nk-content nk-content-fluid">
      <div class="container-xl wide-xl">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="nk-block-head nk-block-head-sm">
              <div class="nk-block-between g-3">
                <div class="nk-block-head-content">
                  <h3 class="nk-block-title page-title">Recorridos / <strong class="text-primary small">Editar
                      Recorrido</strong></h3>
                </div>
                <div class="nk-block-head-content">
                  <router-link to="/sistema/recorridos" replace
                    class="btn btn-outline-light bg-white d-none d-sm-inline-flex"><em
                      class="icon ni ni-arrow-left"></em><span>Regresar</span></router-link>
                  <router-link to="/sistema/recorridos" replace
                    class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"><em
                      class="icon ni ni-arrow-left"></em></router-link>
                </div>
              </div>
            </div><!-- .nk-block-head -->
            <div class="nk-block">
              <div class="card card-bordered">
                <div class="card-aside-wrap">
                  <div class="card-content">
                    <form @submit.prevent="save">
                      <div class="card-inner">
                        <div class="nk-block">
                          <div class="nk-block-head">
                            <h5 class="title">Editar Recorrido</h5>
                          </div><!-- .nk-block-head -->
                          <div class="form-group">
                            <label class="form-label">Nombre <span class="ml-1 text-danger">*</span></label>
                            <input type="text" class="form-control text-uppercase" autocomplete="nope"
                              v-model="model.nombre" required>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Descripción</label>
                            <textarea class="form-control" v-model="model.descripcion"></textarea>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Correo(s) <span class="ml-1 text-danger">*</span></label>
                            <input type="text" class="form-control" autocomplete="nope" v-model="model.email" required>
                            <p class="sub-text">Puedes añadir varios correos separados por coma
                              <span class="font-weight-bold">
                                (Ej: correo@correo.com, correo@correo.com, correo@correo.com)
                              </span>
                            </p>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Servicio <span class="ml-1 text-danger">*</span></label>
                            <SelectServicio class="form-control" v-model:servicio="model.id_servicio"></SelectServicio>
                          </div>
                          <div class="form-group">
                            <label class="form-label">Puntos <span class="ml-1 text-danger">*</span></label>
                            <div class="flex-row justify-between">
                              <p class="sub-text">Da clic en el botón <span class="font-weight-bold">Administrar
                                  Puntos</span>, para añadir o eliminar puntos de interés al recorrido</p>
                              <button type="button" class="mb-2 btn btn-sm btn-outline-dark" @click="addPunto"><em
                                  class="icon ni ni-location mr-1"></em> Administrar Puntos
                              </button>
                            </div>
                            <table class="table table-striped table-bordered">
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">Orden</th>
                                  <th scope="col">Nombre</th>
                                  <th scope="col">Descripción</th>
                                  <th scope="col">ID</th>
                                  <th scope="col">Ubicación</th>
                                </tr>
                              </thead>
                              <draggable v-model="model.puntos" tag="tbody" item-key="id_punto">
                                <template #item="{ element: punto, index }">
                                  <tr>
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ punto.nombre }}</td>
                                    <td>{{ punto.descripcion }}</td>
                                    <td scope="row">{{ punto.id_punto }}</td>
                                    <td><a @click.prevent="verUbicacion(punto)" href="#">Ver Ubicación</a></td>
                                  </tr>
                                </template>
                              </draggable>
                            </table>
                          </div>
                          <hr />
                          <div class="w-100 text-right">
                            <button type="submit" class="mt-2 btn btn-lg btn-primary">Actualizar Recorrido</button>
                          </div>
                        </div><!-- .nk-block -->
                        <div class="nk-block">
                          <div class="nk-block-head nk-block-head-line">
                            <h6 class="title overline-title text-base">Información Adicional</h6>
                          </div><!-- .nk-block-head -->
                          <div class="profile-ud-list">
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Autor</span>
                                <span class="profile-ud-value">{{ model.autor }}</span>
                              </div>
                            </div>
                            <div class="profile-ud-item">
                              <div class="profile-ud wider">
                                <span class="profile-ud-label">Última actualización</span>
                                <span class="profile-ud-value">{{ $filters.fecha(model.fecha_actualizacion) }}</span>
                              </div>
                            </div>
                          </div><!-- .profile-ud-list -->
                        </div><!-- .nk-block -->
                      </div><!-- .card-inner -->
                    </form>
                  </div><!-- .card-content -->
                </div><!-- .card-aside-wrap -->
              </div><!-- .card -->
            </div><!-- .nk-block -->
          </div>
        </div>
      </div>
    </div>
    <!-- content @e -->
    <!-- Modal -->
    <q-dialog v-model="showModal">
      <q-card class="modal-puntos">
        <q-toolbar class="bg-primary text-white">
          <q-toolbar-title>Puntos de interés del recorrido</q-toolbar-title>
          <q-btn flat round dense icon="close" v-close-popup />
        </q-toolbar>
        <q-scroll-area style="height: 85vh;">
          <PuntosTable v-model:selected="model.puntos" class="full-width"></PuntosTable>
        </q-scroll-area>
      </q-card>
    </q-dialog>
  </AppView>
</template>

<script>
import AppView from "@/components/sistema/AppView";
import { defineComponent, reactive, ref, toRefs } from 'vue';
import { useApi } from "@/use/useApi";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
import SelectServicio from "@/components/globales/SelectServicio.vue";
import draggable from "vuedraggable";
import PuntosTable from "@/components/sistema/puntos/PuntosTable.vue";
import { Dialog } from "quasar";

export default defineComponent({
  name: "Editar Recorrido",
  components: { PuntosTable, AppView, SelectServicio, draggable },
  setup() {
    const { loading, make } = useApi();
    const router = useRouter();
    const { params } = useRoute();

    const state = reactive({
      showModal: false
    });

    const model = ref({
      nombre: '',
      descripcion: '',
      email: '',
      id_servicio: '',
      puntos: []
    });

    async function get() {
      loading.message = 'Obteniendo información';
      let { data } = await make('recorridos/get', { id_recorrido: params.id_recorrido });
      if (data === null) {
        await router.push('/sistema/recorridos');
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if (data.error) {
          await router.push('/sistema/recorridos');
          await Swal.fire('Error', data.message, 'error');
        } else {
          model.value = data.model;
        }
      }
    }

    async function save() {
      loading.message = 'Actualizando recorrido';
      let { data } = await make('recorridos/edit', { model: model.value });
      if (data === null) {
        await Swal.fire('Error', 'Ocurrió un error al conectarse con el servidor', 'error');
      } else {
        if (data.error) {
          await Swal.fire('Error', data.message, 'error');
        } else {
          Swal.fire('Éxito', data.message, 'success').then(() => {
            router.push('/sistema/recorridos');
          });
        }
      }
    }

    function addPunto() {
      state.showModal = true;
    }

    function verUbicacion(punto) {
      Dialog.create({
        title: punto.nombre,
        message: `<iframe width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?hl=es&amp;q=${punto.lat},${punto.lng}+(${punto.nombre})&amp;t=&amp;z=19&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />`,
        html: true
      })
    }

    get();

    return { model, ...toRefs(state), save, addPunto, verUbicacion };
  }
});
</script>

<style scoped>
.profile-ud-list {
  max-width: inherit;
}

.profile-ud-label {
  width: 140px;
  align-items: start;
  margin-top: 0.5em;
}

.profile-ud-value {
  line-height: 2.5em;
}

.modal-puntos {
  width: 100%;
  max-width: 95vw;
}
</style>
